@import "/src/styles/abstracts/mixins";

/* ------------------------------- card-mvp ------------------------------- */
.card-mvp {
  border-radius: 24px;
  background: rgba($white, 0.1);
  backdrop-filter: blur(40px);

  overflow: hidden;

  .card-mvp__wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    padding: 40px;

    overflow: hidden;
  }

  .card-mvp__wrapper--mvp-5 {
    padding-bottom: 10px;
  }

  .card-mvp__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .card-mvp__content--left {
    align-items: flex-start;
  }

  .card-mvp__icon {
    height: 48px;
  }

  .card-mvp__text-wrapper {
  }

  .card-mvp__title {
    text-align: center;
  }

  .card-mvp__location {
    color: $white;
    line-height: 120%;
    margin: 0;
    display: flex;
    justify-content: center;
    text-align: center;

    input,
    textarea {
      font-family: "Averta CY", sans-serif;
      font-style: normal;
      font-weight: 700;
      line-height: 120%;
      font-size: 20px;

      color: $white;
      margin: 0;
      display: flex;
      justify-content: center;
      text-align: center;
      background-color: transparent;
      width: 200px;
      height: auto;
      min-height: 96px;
      outline: none;
      border-style: none;
      resize: none;
      overflow-y: hidden;
    }
  }

  .card-mvp__title--left {
    text-align: left;
  }

  .card-mvp__title--sell {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 10px;

    .active {
      text-decoration: underline;
      color: $pink;
    }

    a {
      color: $white;
      transition: all 0.2s ease-in-out;
    }

    a:hover {
      text-decoration: underline;
      color: $pink;
      cursor: pointer;
      transition: all 0.2s ease-in-out;
    }
  }

  .card-mvp__text {
    text-align: center;
  }

  .card-mvp__text--left {
    text-align: left;
  }

  .card-mvp__button-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 100%;
  }

  .card-mvp__button {
  }

  .card-mvp__gallery {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 7px;
    margin: 0 -30px;

    img {
      width: 156.5px;
      height: 160.5px;

      border-radius: 6px;
      object-fit: cover;
    }

    img:first-child {
      width: 320px;
      height: 292px;

      border-radius: 13px;
      object-fit: cover;
    }
  }

  .card-mvp__gallery--two {
    img {
      width: 320px;
      height: 292px;

      border-radius: 13px;
      object-fit: cover;
    }
  }
}

.card-mvp--mvp-5 {
  padding-bottom: 10px;
}

.card-expose {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;

  .document-page__btns-sidebar {
    position: fixed;
    right: 40px;
    bottom: 74px;
    display: flex;
    justify-content: center;
    align-items: center;

    @include respond-to("md") {
      position: fixed;
      left: 0;
      bottom: 0;
      width: 100%;
      background-color: $white;
    }
  }

  .card-expose__control-btns {
    position: absolute;
    top: 40px;
    left: 50%;
    margin-left: auto;
    margin-right: auto;
    display: flex;
    flex-direction: row;
    gap: 16px;

    @include respond-to("lg") {
      left: 30%;
    }

    @include respond-to("sm") {
      gap: 10px;
      top: 20px;
    }
  }

  .card-expose__pages-btns {
    position: absolute;
    top: 40px;
    left: calc(100% - 313px - 96px);
    display: flex;
    flex-direction: row;
    gap: 16px;
    @media (max-width: 1228px) {
      left: 70%;
    }
  }

  .card-expose__control-select {
    width: 89px;
    height: 40px;
  }

  .card-expose__content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    margin-left: 300px;
    padding-top: 40px;
    padding-bottom: 40px;

    @include respond-to("md") {
      margin-left: 0;
      padding-top: 0;
      padding-bottom: 0;
    }

    &.card-expose__content--with-sidebar {
      background-color: $whiteNeutrals;
      //margin-left: 353px;
      //margin-right: 353px;
      margin-top: 120px;
      margin-bottom: 74px;
      //max-width: 1214px;
      max-width: calc(100% - 270px - 313px);

      @include respond-to("lg") {
        max-width: 567px;
      }

      @include respond-to("md") {
        margin-top: 0px;
        max-width: 100%;
        min-width: 320px;
        //background-color: $white;
      }
    }

    &.card-expose__content--no-data {
      background-color: $white;
      height: 80vh;
    }
  }

  .card-expose__icon--inner-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 40px;
    height: 446px;
    width: 500px;

    @include respond-to("sm") {
      width: 220px;
    }
  }
}

.card-settings {
  display: flex;
  flex-direction: column;
  align-items: center;

  .card-settings__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 16px;
  }

  .card-settings__content--bottom-content {
    position: absolute;
    bottom: 40px;
  }

  .card-settings__header {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 40px;
    padding-left: 12px;

    width: 220px;
    cursor: pointer;

    @media all and (max-width: 1300px) {
      width: 161px;
    }

    @include respond-to("sm") {
      width: 150px;
    }
  }

  .settings-page__title {
    color: $neutrals-rich-black;

    &.active {
      color: $blueTotal;
    }
  }

  .settings-page__title--gray {
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 26px;
    color: $silverSonic;

    &.active {
      color: $blackRich;
    }
  }

  .settings-page__menu {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    display: flex;
    padding: 11px 12px;
    gap: 10px;
    cursor: pointer;
    width: 220px;

    @media all and (max-width: 1300px) {
      width: 161px;
    }

    @include respond-to("sm") {
      width: 150px;
    }
  }
}

.section-realty-images__card {
  border-radius: 4px;
  border: 1px dashed #b7b8be;
  background: #fdfdfd;
  width: 273px;
  height: 154px;

  position: relative;

  @media all and (max-width: 1200px) {
    max-width: 300px;
    height: 180px;
  }

  @include respond-to("md") {
    max-width: 320px;
    width: 100%;
    height: 180px;
  }

  &.section-realty-images__card--first-card {
    @include respond-to("sm") {
      height: 263px;
    }
  }
  .section-realty-images__category {
    position: absolute;
    top: 8px;
    left: 8px;
    z-index: 100;
    //width: 124px;
    height: 34px;
    background-color: $background-white;
    color: $greyMetal;
    border-radius: 6px;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
  }

  .section-realty-images__image {
    z-index: 1;

    input {
      z-index: 1;

      width: 273px;
      height: 154px;

      &.disabled {
        pointer-events: none;
      }
    }

    .avatar {
      display: flex;
      width: 273px;
      height: 154px;
      flex-shrink: 0;
      align-self: stretch;
      overflow: hidden;

      position: relative;
      //border: 2px dashed rgba(0, 0, 0, 0.1);
      //border-radius: 16px;

      input {
        outline: none;
        border-radius: 12px;
        display: block;
        width: 273px;
        height: 154px;
        opacity: 0;
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        z-index: 1000;
      }

      .avatar-image {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }

      .avatar--placeholder {
        display: flex;
        width: 100%;
        height: 100%;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        //opacity: 0.4;

        img {
          width: 32px;
          height: 32px;
          opacity: 0.6;
          margin-bottom: 12px;
        }

        .image-text {
          font-family: Averta;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 16px;
          text-align: center;
          color: rgba(#000000, 0.8);

          &.cover {
            font-family: Averta;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 16px;
            color: rgba(#fff, 0.6);
          }
        }
      }
    }

    @media all and (max-width: 1200px) {
      width: 300px;
      height: 180px;

      input {
        width: 300px;
        height: 180px;
      }

      .avatar {
        width: 300px;
        height: 180px;

        input {
          width: 300px;
          height: 180px;
        }
      }
    }

    @media all and (max-width: 768px) {
      width: 320px;
      height: 180px;

      input {
        width: 320px;
        height: 180px;
      }

      .avatar {
        width: 320px;
        height: 180px;

        input {
          width: 320px;
          height: 180px;
        }
      }
    }

    &.section-realty-images__image--first-card {
      width: 320px;
      height: 263px;

      input {
        width: 320px;
        height: 263px;
      }

      .avatar {
        width: 320px;
        height: 263px;

        input {
          width: 320px;
          height: 263px;
        }
      }
    }

    .section-realty-images__btn {
      margin-top: 14px;

      display: flex;
      width: 210px;
      height: 36px;
      padding: 12px 8px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      flex-shrink: 0;

      border-radius: 4px;
      background: $greySoft;
      outline: none;
      border: none;
    }

    .section-realty-images__image--title {
      width: 210px;
      text-align: center;
      margin-top: 8px;
    }
  }
}
