

.modal-wrapper {
  display: flex;
  flex-direction: column;
  gap: 20px;
  height: 100%;

  @include respond-to("sm") {
    max-width: 250px;
  }
}

.modal-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.modal-content {
  display: flex;
  width: 522px;
  align-items: flex-start;
  align-content: flex-start;
  gap: 8px;
  flex-wrap: wrap;
  @include respond-to("sm") {
    width: 250px;

  }
}

.modal-content__card {
  display: flex;
  padding: 8px;
  height: 34px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  border: 1px solid #B7B8BE;
  background: #FDFDFD;
  color: #757780;
  cursor: pointer;

  &.active, &:hover {
    border-radius: 4px;
    //border: #B7B8BE;
    background-color: #EBEDEF;
  }
}

.modal-back {
  display: flex;
  flex-direction: row;
  gap: 12px;

  .modal-back__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    gap: 12px;
    width: 100%;
  }

  .modal-back__icon {

    img {
      width: 11px;
      height: 19px;
    }
  }

  .modal-back__title {

  }

  .modal-back__title:hover {
  }

  .modal-back__step {
    font-weight: 600;
    font-size: 12px;
    line-height: 14px;
    color: rgba(#FFFFFF, 0.6);
  }
}
