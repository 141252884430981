// -----------------------------------------------------------------------------
// This file contains all application-wide Sass mixins.
// -----------------------------------------------------------------------------

// Mixin to manage responsive breakpoints

@mixin respond-to($breakpoint) {
  @if map-has-key($breakpoints, $breakpoint) {
    @media (max-width: map-get($breakpoints, $breakpoint)) {
      @content;
    }
  } @else if ($breakpoint) {
    @media (max-width: $breakpoint) {
      @content;
    }
  }

    // If the key doesn't exist in the map
  @else {
    @warn "Unfortunately, no value could be retrieved from `#{$breakpoint}`. "
		+"Available breakpoints are: #{map-keys($breakpoints)}.";
  }
}
