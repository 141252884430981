// -----------------------------------------------------------------------------
// This file contains very basic styles.
// -----------------------------------------------------------------------------
html,
body {
  width: 100%;
  height: 100%;
  background: #0A0909;
}

body {
  background: #0A0909;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

img {
  display: block;
}
