// -----------------------------------------------------------------------------
// This file contains all @font-face declarations, if any.
// -----------------------------------------------------------------------------

/* ----------------------------- SF Pro Display ----------------------------- */
@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 400;
  src: url("../../assets/fonts/SF-Pro-Display/SF-Pro-Display-Regular.ttf");
}

@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 500;
  src: url("../../assets/fonts/SF-Pro-Display/SF-Pro-Display-Medium.ttf");
}

@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 700;
  src: url("../../assets/fonts/SF-Pro-Display/SF-Pro-Display-Bold.ttf");
}

/* ----------------------------- Averta CY ----------------------------- */
@font-face {
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 400;
  src: url("../../assets/fonts/Averta-CY/Averta-CY-Regular.ttf");
}

@font-face {
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 600;
  src: url("../../assets/fonts/Averta-CY/Averta-Cyrillic-SemiBold.otf");
}

@font-face {
  font-family: "Averta CY";
  font-style: normal;
  font-weight: 700;
  src: url("../../assets/fonts/Averta-CY/Averta-CY-Bold.otf");
}

/* ----------------------------- Plus Jakarta Sans ----------------------------- */
@font-face {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 400;
  src: url("../../assets/fonts/Plus Jakarta Sans/Plus-Jakarta-Sans-Regular.ttf");
}

@font-face {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 500;
  src: url("../../assets/fonts/Plus Jakarta Sans/Plus-Jakarta-Sans-Medium.ttf");
}

@font-face {
  font-family: "Plus Jakarta Sans";
  font-style: normal;
  font-weight: 600;
  src: url("../../assets/fonts/Plus Jakarta Sans/Plus-Jakarta-Sans-SemiBold.ttf");
}


@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 500;
  src: url("../../assets/fonts/SF-Pro-Display/SF-Pro-Display-Medium.ttf");
}

@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 500;
  src: url("../../assets/fonts/SF-Pro-Display/SF-Pro-Display-Medium.ttf");
}

@font-face {
  font-family: "SF Pro Display";
  font-style: normal;
  font-weight: 600;
  src: url("../../assets/fonts/SF-Pro-Display/SF-Pro-Display-Medium.ttf");
}

@font-face {
  font-family: "SF Mono";
  font-style: normal;
  font-weight: 700;
  src: url("../../assets/fonts/SFMono/SFMono-Medium.otf");
}

@font-face {
  font-family: "SF Mono";
  font-style: normal;
  font-weight: 600;
  src: url("../../assets/fonts/SFMono/SFMono-Medium.otf");
}

@font-face {
  font-family: "SF Mono";
  font-style: normal;
  font-weight: 500;
  src: url("../../assets/fonts/SFMono/SFMono-Bold.otf");
}


@font-face {
  font-family: "SF Mono";
  font-style: normal;
  font-weight: 700;
  src: url("../../assets/fonts/SFMono/SFMono-Bold.otf");
}




@font-face {
  font-family: "SF Mono";
  font-style: normal;
  font-weight: 900;
  src: url("../../assets/fonts/SFMono/SFMono-Heavy.otf");
}

@font-face {
  font-family: "SF Mono";
  font-style: normal;
  font-weight: 900;
  src: url("../../assets/fonts/SFMono/SFMono-Heavy.otf");
}